import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				Нотаріус Професіонал
			</title>
			<meta name={"description"} content={"Ваш надійний юридичний партнер"} />
			<meta property={"og:title"} content={"Нотаріус Професіонал"} />
			<meta property={"og:description"} content={"Ваш надійний юридичний партнер"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
		</Helmet>
		<Components.Marque />
		<Components.Header>
			<Override slot="linkBox" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
		</Components.Header>
		<Section padding="100px 0 100px 0" background="--color-darkL2" quarkly-title="FAQ-7">
			<Text margin="0px 0px 2rem 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				Поширені запитання
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Які документи необхідні для посвідчення угоди купівлі-продажу?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Для посвідчення угоди купівлі-продажу нерухомості вам необхідно надати паспорт, ідентифікаційний код, документи на нерухомість (свідоцтво про право власності, технічний паспорт) та, за наявності, попередній договір.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Як записатися на прийом до нотаріуса?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Ви можете записатися на прийом через телефон або заповнивши форму на нашому сайті. Ми також пропонуємо можливість запису на онлайн-консультацію через відеозв’язок.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Скільки часу займає оформлення спадщини?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Оформлення спадщини може зайняти від декількох тижнів до декількох місяців, залежно від складності справи та наявності всіх необхідних документів. Ми завжди намагаємося здійснювати процес якнайшвидше.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи можливо оформити довіреність без присутності довірителя?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Ні, для оформлення довіреності необхідна особиста присутність довірителя, оскільки він повинен підписати документ в присутності нотаріуса.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Які послуги надаються онлайн?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Ми надаємо послуги онлайн-консультацій, де ви можете отримати відповіді на свої питання та домовитися про нотаріальні дії. Однак деякі документи вимагають особистої присутності для підпису.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Яка вартість нотаріальних послуг?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Вартість нотаріальних послуг залежить від типу та складності нотаріальної дії. Ви можете ознайомитися з орієнтовними цінами на нашому сайті або дізнатися точну вартість під час консультації з нотаріусом.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Header />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});